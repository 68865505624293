export const indonesiaOnlineTerms = (
  <>
    <h1 className="text-2xl mt-2">Peserta Online:</h1>
    <li>
      Peserta yang tidak mengumpulkan dokumen persyaratan (extended abstract,
      PowerPoint) setelah dua kali pengingat akan dianggap mengundurkan diri
      secara otomatis.
    </li>
    <li>
      Peserta wajib menggunakan judul dan extended abstract yang sesuai dengan
      kategori yang diikuti. (Peserta internasional, wajib menggunakan bahasa
      Inggris untuk seluruh persyaratan dokumen yang dibutuhkan)
    </li>
    <li>
      Keputusan panitia terkait hal ini bersifat final dan tidak dapat diganggu
      gugat.
    </li>
  </>
);

export const indonesiaOfflineTerms = (
  <>
    <h1 className="text-2xl">Peserta Offline:</h1>
    <li>
      Peserta yang tidak mengumpulkan berkas persyaratan (extended abstract,
      poster) setelah dua kali pengingat akan dianggap mengundurkan diri secara
      otomatis.
    </li>
    <li>
      Peserta wajib menggunakan judul dan extended abstract yang sesuai dengan
      kategori yang diikuti. (Peserta internasional, wajib menggunakan bahasa
      Inggris untuk seluruh requirement)
    </li>
    <li>
      Peserta diwajibkan untuk membuat poster berukuran A0, membawa produk, dan
      juga paper/extended abstract (dalam bentuk hard copy) pada saat sesi
      penjurian.
    </li>
    <li>
      Pemberian penghargaan kepada peserta akan dilakukan sesuai dengan urutan
      yang telah ditetapkan dalam susunan acara (peserta tidak diperkenankan
      untuk meminta award sebelum gilirannya).
    </li>
    <li>
      Peserta diwajibkan untuk mengikuti seluruh rangkaian kegiatan sesuai
      dengan jadwal yang telah disusun oleh panitia penyelenggara.
    </li>
    <li>
      Bagi peserta offline yang berhak atas hadiah Best booth, Best Poster, Best
      Presentation, Best Project, uang tunai, semi grand award, dan grand award
      namun tidak dapat hadir pada acara penyerahan hadiah, maka hadiah yang
      bersangkutan dinyatakan hangus.
    </li>
    <li>
      Keputusan panitia terkait hal ini bersifat final dan tidak dapat diganggu
      gugat.
    </li>
  </>
);

export const internationalOnlineTerms = (
  <>
    <h3 className="text-2xl">Online Participants:</h3>
      <li>
        Participants who do not submit the required documents (extended
        abstract, PowerPoint) after two reminders will be considered to have
        resigned automatically.
      </li>
      <li>
        Participants must use the title and extended abstract in accordance with
        the category they are participating in. (International participants must
        use English for all required documents)
      </li>
      <li>
        The committee&apos;s decision regarding this matter is final and cannot
        be contested.
      </li>
  </>
);

export const internationalOfflineTerms = (
  <>
    <h3 className="text-2xl">Offline Participants:</h3>
      <li>
        Participants who do not submit the required files (extended abstract,
        poster) after two reminders will be considered automatically resigned.
      </li>
      <li>
        Participants must use titles and extended abstracts that are in
        accordance with the category they are participating in. (International
        participants must use English for all requirements)
      </li>
      <li>
        Participants are required to make an A0-sized poster, bring the product
        and also the paper/extended abstract (in hard copy) during the judging
        session.
      </li>
      <li>
        The awarding of awards to participants will be carried out in accordance
        with the order set out in the schedule of events (participants are not
        allowed to request awards before their turn).
      </li>
      <li>
        Participants are required to follow the entire series of activities in
        accordance with the schedule prepared by the organizing committee.
      </li>
      <li>
        For offline participants who are entitled to the Best booth, Best
        Poster, Best Presentation, Best Project, cash, semi grand award, and
        grand award prizes but cannot attend the prize distribution ceremony,
        the prizes concerned will be declared forfeited.
      </li>
      <li>
        The committee&apos;s decision regarding this matter is final and cannot
        be contested.
      </li>
  </>
);
